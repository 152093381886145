import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import image from '../images/logo.png';

function PageDashboard() {
    return (
        <Container style={{paddingTop:180}}>
            <Row xs={1} sm={2} md={3}>
                <Col>
                    <Button variant="light" className="dropShadow" as={Link} to="/links">
                        <img src={image} alt={image} width={300}/>
                        <hr/>
                        <h3>Boslinks</h3>
                    </Button>
                </Col>
                <Col>
                    <Button variant="light" className="dropShadow" as={Link} to="/pages">
                    <img src="https://img.icons8.com/external-flatarticons-blue-flatarticons/300/000000/external-webpage-seo-flatarticons-blue-flatarticons.png" alt="pages"/>
                        <hr/>
                        <h3>BOS Pages</h3>
                    </Button>
                </Col>
                <Col>
                    <Button variant="light" className="dropShadow" as={Link} to="/stats">
                        <img src="https://img.icons8.com/external-sbts2018-flat-sbts2018/300/000000/external-statistics-economy1-sbts2018-flat-sbts2018.png" alt="stats"/>
                        <hr/>
                        <h3>Statistics</h3>
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

export default PageDashboard
