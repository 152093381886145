import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Form, FormControl, Modal, Row } from 'react-bootstrap'
import swal from 'sweetalert';
import Detail from '../components/Detail';
import SocialIcon from '../components/icons/SocialIcon';
import Switch from "react-switch";
import TypeIcon from '../components/icons/TypeIcon';
import * as API from "../utils/api";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import logo from "../images/logo.png";
import { useHistory } from 'react-router-dom';


function PageMyLinks() {
    
    //constants
    const types = ["Link", "Video", "Image"];
    const MAX_DESC_LENGTH = 200;
    

    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [url, setUrl] = useState("");
    const [name, setName] = useState("");
    const [registerFirst, setRegisterFirst] = useState(false);
    const [registrationPlatform, setRegistrationPlatform] = useState("-1");
    const [show, setShow] = useState(false);
    const [description, setDescription] = useState("");
    const [resourceType, setResourceType] = useState("-1");
    const [popup, setPopup] = useState(false);
    const history = useHistory();

    useEffect(() => {
        async function run(){
            try{
                const resources = await API.GetAPI(`/api/boslinks`);
                setResources(resources || []);
            }catch(e){
                console.log(e.message);
                setResources([]);
            }finally{
                
            }
        }
        run();
    }, [])
   

    const onAdd = async (e) => {
        e.preventDefault();

        const res =   {
            name: name,
            description:description,
            url:url,
            type:resourceType,
            registerFirst:registerFirst,
            registrationPlatform:registrationPlatform,
            show: show,
        } 

        //check if the resource url exists
        if(resources.find(r => r.name === res.name) && editIndex === -1){
            swal(`Resources with url ${res.url} already exists`);
        }else if(description.length > MAX_DESC_LENGTH){
            swal(`Description TOO long`);
        }else if(res.registerFirst && res.registrationPlatform === "-1"){
            swal(`Select a platform for users to register with`);
        }else{

            try{
                setLoading(true);

                // POST or PATCH (add or update)
                const response = editIndex === -1 ? 
                    await API.PostAPI('/api/boslinks', res) : 
                    await API.PatchAPI(`/api/boslinks/${editIndex}`, res);
                
                if(response.result){

                    swal(response.message);
                    const resources = await API.GetAPI(`/api/boslinks`);
                    setResources(resources);
                    //clear fields
                    e.target.name.value = "";
                    e.target.description.value = "";
                    e.target.url.value = "";
                    setShow(false);
                    setUrl("")
                    setDescription("");
                    setEditIndex(-1);
                }
            }catch(e){
                console.log(e.message);
            }finally{
                setLoading(false);
            }
            
        }
    }

    const onDel = async (res) => {
        const name = resources.find(r => r.name === res.name).name
        const options = {
            title: `Delete ${name}`,
            text: `Do you want to delete blocked option for ${name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }
        const result = await swal(options)
        

        //when user accepts delete option
        if (result) {
            const list = [];
            resources.forEach(resource=>{
                if(resource.name !== res.name){
                    list.push(resource);
                }
            });
            setResources(list);
        } 
    }

    const onResourceShow = async (res, value) => {
        try {
            const index = resources.findIndex(r=>r.url === res.url);
            resources[index].show = value;
            const resource = resources[index];
            const response = await API.PatchAPI(`/api/boslinks/${resource._id}`, resource);
            if(response.result){
                setResources([...resources]);
            }
            
        }catch(e){
            console.log(e.message);
        }finally{
            
        }
    }

    const onEditingResources = (res) => {
        window.scrollTo(0, 0); 
        setEditIndex(res._id);
        setName(res.name);
        setDescription(res.description);
        setResourceType(res.type);
        setUrl(res.url);
        setPopup(true);
    }

    const onDragEnd = (result) => {
        if (!result.destination) return;
    
        const items = resources;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setResources([...items]);
    }

    const onClosePopup = () => {
        setEditIndex(-1);
        setShow(false);
        setUrl("")
        setDescription("");
        setEditIndex(-1);
        setPopup(false);
    }

    return (
        <Container style={{paddingTop:80, paddingBottom:40}} className="centralise">

            <br/><br/>

            <Button variant="light" className="dropShadow" onClick={()=>history.goBack()}> 
                <strong>Back to Menu</strong>
            </Button>

            <br/><br/>
            
            <img src={logo} alt="logo" width={150}/>
            <h1>My BOS Links</h1>
            {/* <h4><i>You can drag and drop the list to <strong>organise</strong> the order</i></h4> */}
            <br/>
            <div className="centralise">
                    <Button variant={loading?"outline-info":"info"} className="round hover dropShadow" onClick={()=>setPopup(true)}>
                        <img src="https://img.icons8.com/ios-filled/30/ffffff/link.png" alt="res"/>
                        {" "}
                        <strong>{loading?"Loading":"Add Boslink"}</strong>
                    </Button>
            </div>
            <br/>
            <Container>
                    {/**https://www.youtube.com/watch?v=y1w6C9A5a2A */}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="social">
                            {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {resources.map((res, index) => {
                                return (
                                    <Draggable key={'d'+index} draggableId={'j'+index} index={index}>
                                    {(provided) => (
                                        <Alert variant="light" style={{alignContent:"left", alignItems:"left"}} className="dropShadow" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <Row xs={3}>
                                                <Col>
                                                    {res.type === "Link" ? <SocialIcon url={res.url}/> : <TypeIcon type={res.type}/>}<Detail text={"Type: " + res.type} max={250}/>
                                                </Col>
                                                <Col style={{alignContent:"left", alignItems:"left"}}>
                                                    <h6><strong>{res.name}</strong><i>({res.description})</i></h6>
                                                </Col>
                        
                                            </Row>
                                            <h5><a href={res.url}>{res.url}</a></h5>
                                            <Row xs={1} sm={2}>
                                                <Col>
                                                    <Button variant="light" className="round hover dropShadow" onClick={()=>onEditingResources(res)}>
                                                        <strong>EDIT BOSLINK</strong>
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button variant="light" className="round hover dropShadow" onClick={()=>onDel(res)}>
                                                        <strong>DELETE BOSLINK</strong>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Alert>
                                    )}
                                    </Draggable>
                                );
                                })}
                                {provided.placeholder}
                            </div>
                            )}
                        </Droppable>
                    </DragDropContext>                    
     
            </Container>
            <br/>
            <Modal show={popup} onHide={onClosePopup}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex === -1? "ADD BOS LINKS" : "Edit " + resources.find(r=>r._id===editIndex).name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={onAdd}>
                        
                        <Form.Label>Name</Form.Label>
                        <FormControl disabled={loading} required name="name" type="name" placeholder="Name" value={name} onChange={(e)=>setName(e.target.value)}/>
                        <br/>
                        <Form.Label><SocialIcon url={url} />{" "}Type</Form.Label>
                        <FormControl disabled={loading} required name="type" as="select" value={resourceType} onChange={(e)=>setResourceType(e.target.value)} placeholder="Resources Type">
                            <option disabled value="-1">Choose Resource Type</option>
                            {types.map(type=><option key={type} value={type}>{type}</option>)}
                        </FormControl>
                        <br/>
                        <Form.Label>Url</Form.Label>
                        <FormControl disabled={loading} required name="url" type="url" value={url} onChange={(e)=>setUrl(e.target.value)} placeholder="URL link for resources" max={300}/>
                        <br/>
                        <Form.Label>Must Register First</Form.Label>
                        <Form.Check disabled={loading} required name="register" label="Must Register First" checked={registerFirst} onChange={(e)=>setRegisterFirst(e.target.checked)}size="lg"/>
                        <br/>
                        {registerFirst?
                        <div>
                            <Form.Label>Register With</Form.Label>
                            <FormControl disabled={loading} required name="type" as="select" value={registrationPlatform} onChange={(e)=>setRegistrationPlatform(e.target.value)} placeholder="Register With">
                                <option disabled value="-1">Register With</option>
                                <option value="google">Google</option>
                                <option value="facebook">Facebook</option>
                                <option value="linkedin">Linkedin</option>
                                <option value="tiktok">Tiktok</option>
                            </FormControl>
                            <br/>
                        </div>
                        :null
                        }
                        <Form.Label>Show In Frame</Form.Label>
                        <br/>
                        <Switch name="show" checked={show} onChange={setShow} width={63} height={25} />
                        <br/><br/>
                        <Form.Label style={{color:description.length > MAX_DESC_LENGTH ? "red":""}}>Brief Description ({description.length}/{MAX_DESC_LENGTH})</Form.Label>
                        <FormControl disabled={loading} required name="description" onChange={(e) => setDescription(e.target.value)} value={description} type="text" as="textarea" style={{height:100}} placeholder="Brief description of the resource..." max={MAX_DESC_LENGTH}/>
                        <br/>
                        <br/>
                        <button type="submit" style={{display:"none"}} id="button"/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClosePopup}>
                        <strong>CANCEL</strong>
                    </Button>
                    <label htmlFor="button">
                        <Button variant="info" onClick={()=>document.getElementById('button')?.click()}>
                            <strong>{editIndex === -1?"ADD":"EDIT"}</strong>
                        </Button>
                    </label>
                </Modal.Footer> 
            </Modal>
        </Container>
    )
}

export default PageMyLinks
