import React from 'react'

function PoweredByBOS() {
    return (
        <div style={{position:"fixed", right:10, bottom:10, color:"#8A8A8A"}}>
            <hr/>
            <h4>Powered by <strong>BOS</strong></h4>
        </div>
    )
}

export default PoweredByBOS
