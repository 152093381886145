
import rumble from "../../images/rumble.png";

const SocialIcon = ({url}) => {
    if(url.match(/(^https:\/\/rumble\.com\/|^https:\/\/.*rumble\.com\/)/gmi)){
        return <img src={rumble}  style={{float:"left"}} alt="icon" height={30} />
    }else if(url.match(/(^https:\/\/messenger\.com\/t\/.*|^https:\/\/.*messenger.com\/t\/.*|^https:\/\/.*facebook.com\/messages\/t\/.*|^https:\/\/facebook.com\/messages\/t\/.*|^https:\/\/m.me\/.*)/gim)){
        return <img src="https://img.icons8.com/fluency/30/000000/facebook-messenger--v2.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/facebook\.com\/|^https:\/\/.*facebook\.com\/)/gmi)){
        return <img src="https://img.icons8.com/fluency/30/000000/facebook-new.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/whatsapp\.com\/|^https:\/\/.*whatsapp\.com\/)/gmi)){
        return <img src="https://img.icons8.com/color/30/000000/whatsapp.png"  style={{float:"left"}} alt="icon" />
    }else if(url.match(/(^https:\/\/youtube\.com\/|^https:\/\/.*youtube\.com\/|^https:\/\/youtu\.be\/|^https:\/\/.*youtu\.be\/)/gmi)){
        return <img src="https://img.icons8.com/fluency/30/000000/youtube-play"  style={{float:"left"}} alt="icon" />
    }else if(url.match(/(^https:\/\/snapchat\.com\/|^https:\/\/.*snapchat\.com\/)/gmi)){
        return <img src="https://img.icons8.com/fluency/30/000000/snapchat.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/instagram\.com\/|^https:\/\/.*instagram\.com\/)/gmi)){
        return <img src="https://img.icons8.com/fluency/30/000000/instagram-new.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/twitter\.com\/|^https:\/\/.*twitter\.com\/)/gmi)){
        return <img src="https://img.icons8.com/fluency/30/000000/twitter.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/twitch\.com\/|^https:\/\/.*twitch\.com\/)/gmi)){
        return <img src="https://img.icons8.com/external-justicon-flat-justicon/30/000000/external-twitch-social-media-justicon-flat-justicon.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/tiktok\.com\/|^https:\/\/.*tiktok\.com\/)/gmi)){
        return <img src="https://img.icons8.com/fluency/30/000000/tiktok.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/pinterest\.com\/|^https:\/\/.*pinterest\.com\/)/gmi)){
        return <img src="https://img.icons8.com/color/30/000000/pinterest.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/reddit\.com\/|^https:\/\/.*reddit\.com\/)/gmi)){
        return <img src="https://img.icons8.com/dusk/30/000000/reddit.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/linkedin\.com\/|^https:\/\/.*linkedin\.com\/)/gmi)){
        return <img src="https://img.icons8.com/color/30/000000/linkedin.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/discord\.com\/|^https:\/\/.*discord\.com\/)/gmi)){
        return <img src="https://img.icons8.com/fluency/30/000000/discord-logo.png"  style={{float:"left"}} alt="icon"/>
    }else if(url.match(/(^https:\/\/patreon\.com\/|^https:\/\/.*patreon\.com\/)/gmi)){
        return <img src="https://img.icons8.com/color/30/000000/patreon.png"  style={{float:"left"}} alt="icon"/>
    }else{
        return null;
    } 
}

export default SocialIcon;