import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import TypeIcon from '../components/icons/TypeIcon';

function ProfileDisplay({settings, page, boslinks, social, displaySettings}) {

    const buttonStyle = settings.buttons.style;
    return (
        <div className="centralise" style={displaySettings}>
        <Container fluid="sm" className="centralise" style={{padding:30, width:300}}>
            
            <img className={`hover ${settings.profile.dropShadow?"dropShadow":""}`} src={settings.profile.image} width={settings.profile.width} alt="profile" style={{borderRadius:"50%"}}/>
            <h1 style={settings.h1}><strong>{settings.profile.name}</strong></h1>
            <h3 style={settings.h3}>{settings.profile.bio}</h3>
            <br/>

            <Container fluid="sm">
                <Row className="centralise" xs={3} style={{width:200}}>
                    <Col>
                        <img className="hover" src="https://img.icons8.com/color/30/youtube-play.png" alt="youtube" style={{borderRadius:"50%"}}/>
                    </Col>
                    <Col>
                        <img className="hover" src="https://img.icons8.com/color/30/facebook-new.png" alt="facebook" style={{borderRadius:"50%"}}/>
                    </Col>
                    <Col>
                        <img className="hover" src="https://img.icons8.com/color/30/linkedin-circled.png" alt="linkedin" style={{borderRadius:"50%"}}/>
                    </Col>
                    {/*
                    <Col>
                        <img className="hover" src="https://img.icons8.com/nolan/60/twitter-circled.png" alt="twitter" style={{borderRadius:"50%"}}/>
                    </Col>
                    <Col>
                        <img className="hover" src="https://img.icons8.com/nolan/60/instagram-new.png" alt="instagram" style={{borderRadius:"50%"}}/>
                    </Col>
                    <Col>
                        <img className="hover" src="https://img.icons8.com/nolan/60/whatsapp.png" alt="whatsapp" style={{borderRadius:"50%"}}/>
                    </Col>
                    <Col>
                        <img className="hover" src="https://img.icons8.com/nolan/60/pinterest.png" alt="pinterest" style={{borderRadius:"50%"}}/>
                    </Col>
                    <Col>
                        <img className="hover" src="https://img.icons8.com/nolan/60/twitch.png" alt="twitch" style={{borderRadius:"50%"}}/>
                    </Col>
                    */}
                </Row>
                
                
            </Container>
            
            <br/><br/>
            {
                boslinks.map(boslink=>
                    <div  key={boslink._id} style={{paddingBottom:5}}>
                        <Button className={`hover ${settings.buttons.dropShadow?"dropShadow":""}`} variant={settings.buttons.variant} style={buttonStyle} as="a" target="_blank" href={boslink.url}>
                            <TypeIcon size={16} type={boslink.type}/>
                            {"   "}
                            <strong style={{color: settings.buttons.style.fontColor}}>{boslink.name.toUpperCase()}</strong>
                        </Button>    
                    </div>
                   
                )
            }
        </Container>
        </div>
    )
}

export default ProfileDisplay
