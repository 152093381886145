/**
 * Author: Martin Kululanga
 * Github: https://github.com/m2kdevelopments
*/


import React, { useEffect, useState } from "react"
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PoweredByBOS from "./components/PoweredByBOS";
import ScrollToTop from "./components/ScrollToTop";
import PageLogin from "./pages/PageLogin";
import { getAuth } from "firebase/auth";
import Navigation from "./components/Navigation";
import PageMyLinks from "./pages/PageMyLinks";
import PageMyPages from "./pages/PageMyPages";
import PageCustomize from "./pages/PageCustomize";
import PageMobilePreview from "./pages/PageMobilePreview";
import PageProfile from "./pages/PageProfile";
import PageDashboard from "./pages/PageDashboard";
 

export const ContextUser = React.createContext();
export const ContextSetUser = React.createContext();

function App() {

  const [user, setUser] = useState(null);

  // Firebase Authentication
  useEffect(() => getAuth().onAuthStateChanged(async (u) => setUser(u)), []);

  return (
          <Router>
                <ScrollToTop />
                <Switch>
                  <ContextUser.Provider value={user}>
                    <ContextSetUser.Provider value={setUser}>
                      <Navigation/>
                      <Route exact path="/" component={PageLogin} />
                      <Route exact path="/dashboard" component={PageDashboard} />
                      <Route exact path="/links" component={PageMyLinks}/>
                      <Route exact path="/pages" component={PageMyPages}/>
                      <Route exact path="/profile" component={PageProfile}/>
                      <Route path="/page/:pageId" component={PageCustomize}/> 
                      <Route path="/mobile/:pageId" component={PageMobilePreview}/> 
                    </ContextSetUser.Provider> 
                  </ContextUser.Provider>         
                </Switch>
                <PoweredByBOS />
            </Router>
  );
}

export default App;
