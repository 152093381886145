import { useContext, useEffect, useState } from "react";
import { Button, Form, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ContextUser } from "../App";
import logo from '../images/logo.png'; //import images
import {getAuth, signOut} from "firebase/auth"; // Firebase App (the core Firebase SDK) is always required and must be listed first
import swal from "sweetalert";
import Cookies from 'js-cookie';


function Navigation () {

    const [width, setWidth] = useState();
    const user = useContext(ContextUser);
    const history = useHistory();

    
    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        return () => window.removeEventListener("resize", ()=>setWidth(window.innerWidth));
    });

    const onLogout = async () => {
        Cookies.set(process.env.REACT_APP_COOKIE_KEY, null);
        await signOut(getAuth());
        swal("Logged Out!", `You've logged out. Come back again`, 'success');
        history.push("/"); // go to route link
    }

    
    if(width < 1000){
 
        return  <Navbar expand="sm" bg="dark" variant="dark" style={{position:"sticky"}}>
                    <Navbar.Brand as={Link} to={user === null ? "/" : "/dashboard"}>
                        <img alt="BOS Links" src={logo} height={30} className="d-inline-block align-top"/>
                        <Nav.Link as={Link} to={user === null ? "/" : "/dashboard"}>BOS Links</Nav.Link>
                    </Navbar.Brand>
                    <Nav className="mr-auto">
                    
                    </Nav>
                    {
                        user === null ? null :
                    <NavDropdown  id="collasible-nav-dropdown" title={<Button variant="dark">
                        <img src="https://img.icons8.com/ios-filled/40/ffffff/menu.png" alt="menu"/></Button> }>
                        <NavDropdown.Item as={Link} to="/profile" className="hover">{user.displayName.split(" ")[0]}</NavDropdown.Item>
                        <Button onClick={onLogout} variant="light" className="round">
                            <img src="https://img.icons8.com/glyph-neue/24/000000/shutdown.png" alt=""/>
                            {" "}
                            <strong>Log Out</strong>
                        </Button>
                    </NavDropdown>
                    }
                </Navbar>
  
    }
 
    return (
        <>
        <Navbar expand="sm" bg="dark" variant="dark" style={{position:"fixed", width:"100%", zIndex:100}}>
            <Navbar.Brand as={Link} to="/">
                <img alt="BOS Links" src={logo} height={30} className="d-inline-block align-top"/>
            </Navbar.Brand>
            <Nav className="mr-auto">
                <Nav.Link as={Link} to={user === null ? "/" : "/dashboard"}>BOS Links</Nav.Link>
            </Nav>
            <Form inline>
                {
                    user === null ? null : <>
                    <Nav.Link as={Link} to="/profile">{user.displayName.split(" ")[0]}</Nav.Link>
                
                    <Button onClick={onLogout} variant="dark" className="round hover">
                        <img src="https://img.icons8.com/glyph-neue/24/ffffff/shutdown.png" alt=""/>
                        {" "}
                        <strong>Log Out</strong>
                    </Button>
                </>
                }
            </Form>
            
        </Navbar>
       
        </>
    );
    
}

export default Navigation;