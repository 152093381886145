import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  FormControl
} from "react-bootstrap";
import { AnimationLoading } from "../components/Lottie";
import rumble from "../images/rumble.png";
import * as API from "../utils/api";
import swal from "sweetalert";
import { ContextUser } from "../App";

export default function PageProfile() {
  const [loading, setLoading] = useState(false);
  const [social, setSocial] = useState({});
  const user = useContext(ContextUser);

  useEffect(() => {
    async function run(){
      try{
        setLoading(true)
        const res = await API.GetAPI('/api/user');
        setSocial(res.user);
        setLoading(false)
      }catch(e){
        console.log(e.message);
      }
    }

    run();
  }, [])

  const onSocial = async (e) => {
    e.preventDefault();

    const socialLinks = {
      name: e.target.name.value,
      website: e.target.website.value,
      youtube: e.target.youtube.value,
      facebook: e.target.facebook.value,
      twitter: e.target.twitter.value,
      instagram: e.target.instagram.value,
      tiktok: e.target.tiktok.value,
      linkedin: e.target.linkedin.value,
      discord: e.target.discord.value,
      twitch: e.target.twitch.value,
      pinterest: e.target.pinterest.value,
      snapchat: e.target.snapchat.value,
      rumble: e.target.rumble.value,
      whatsapp: e.target.whatsapp.value,
    };

    try {
      setLoading(true);
      const res = await API.PatchAPI("/api/user", socialLinks);
      swal(res.message);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  const onClear = () => {};

  if (loading) {
    return <AnimationLoading width={400} title="Loading Social Links" />;
  }
 
  return (
    <Container className="centralise" style={{ paddingTop: 100 }}>
      <img src={social.image} alt="logo" width={150} />
      <h1>PROFILE</h1>

      <Form onSubmit={onSocial}>
        <br />
        <Form.Label>Display Name</Form.Label>
        <FormControl
          name="displayname"
          defaultValue={user?.displayName}
          type="name"
          size="lg"
          placeholder="Display Name"
        />

        <br />
        <br />
        <div className="centralise">
          <Button
            variant="outline-warning"
            size="lg"
            className="hover round dropShadow"
            onClick={onClear}
          >
            <img
              src="https://img.icons8.com/ios-filled/40/ffc107/clear-symbol.png"
              alt="clear"
            />{" "}
            <strong>CLEAR</strong>
          </Button>{" "}
          <Button
            variant="warning"
            size="lg"
            className="round hover dropShadow"
            type="submit"
          >
            <img
              src="https://img.icons8.com/glyph-neue/40/ffffff/approve-and-update.png"
              alt="update"
            />{" "}
            <strong>UPDATE</strong>
          </Button>
        </div>

        <br />
        <br />

        <Form.Label>
          <img
            src="https://img.icons8.com/fluency/48/000000/domain.png"
            alt="website"
          />{" "}
          <strong>WEBSITE</strong>
        </Form.Label>
        <FormControl
          name="website"
          defaultValue={social?.website}
          type="url"
          size="lg"
          placeholder="Website"
        />
        <br />
        <Form.Label>
          {" "}
          <img
            src="https://img.icons8.com/external-prettycons-flat-prettycons/47/000000/external-youtube-multimedia-prettycons-flat-prettycons.png"
            alt="youtube"
          />{" "}
          <strong>YOUTUBE</strong>
        </Form.Label>
        <FormControl
          name="youtube"
          defaultValue={social?.youtube}
          type="url"
          size="lg"
          placeholder="Youtube Link"
        />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/color/48/000000/facebook.png"
            alt="facebook"
          />{" "}
          <strong>FACEBOOK</strong>
        </Form.Label>
        <FormControl
          name="facebook"
          defaultValue={social?.facebook}
          type="url"
          size="lg"
          placeholder="Facebook Link"
        />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/color/48/000000/twitter.png"
            alt="twitter"
          />{" "}
          <strong>TWITTER</strong>
        </Form.Label>
        <FormControl
          name="twitter"
          defaultValue={social?.twitter}
          type="url"
          size="lg"
          placeholder="Twitter Link"
        />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/fluency/48/000000/instagram-new.png"
            alt="instagram"
          />{" "}
          <strong>INSTAGRAM</strong>
        </Form.Label>
        <FormControl
          name="instagram"
          defaultValue={social?.instagram}
          type="url"
          size="lg"
          placeholder="Instagram Link"
        />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/fluency/48/000000/tiktok.png"
            alt="tiktok"
          />{" "}
          <strong>TIKTOK</strong>
        </Form.Label>
        <FormControl
          name="tiktok"
          defaultValue={social?.tiktok}
          type="url"
          size="lg"
          placeholder="Tiktok Link"
        />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/fluency/48/000000/linkedin.png"
            alt="linkedin"
          />{" "}
          <strong>LINKEDIN</strong>
        </Form.Label>
        <FormControl
          name="linkedin"
          defaultValue={social?.linkedin}
          type="url"
          size="lg"
          placeholder="Linkedin Link"
        />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/fluency/48/000000/discord.png"
            alt="discord"
          />{" "}
          <strong>DISCORD</strong>
        </Form.Label>
        <FormControl
          name="discord"
          defaultValue={social?.discord}
          type="url"
          size="lg"
          placeholder="Discord Link"
        />
        <br />
        <Form.Label>
          {" "}
          <img
            src="https://img.icons8.com/fluency/48/000000/twitch.png"
            alt="twitch"
          />{" "}
          <strong>TWITCH</strong>
        </Form.Label>
        <FormControl
          name="twitch"
          defaultValue={social?.twitch}
          type="url"
          size="lg"
          placeholder="Twitch Link"
        />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/fluency/48/000000/pinterest.png"
            alt="pinterest"
          />{" "}
          <strong>PINTEREST</strong>
        </Form.Label>
        <FormControl
          name="pinterest"
          defaultValue={social?.pinterest}
          type="url"
          size="lg"
          placeholder="Pinterest Link"
        />
        <br />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/fluency/48/000000/snapchat.png"
            alt="snapchat"
          />{" "}
          <strong>SNAPCHAT</strong>
        </Form.Label>
        <FormControl
          name="snapchat"
          defaultValue={social?.snapchat}
          type="url"
          size="lg"
          placeholder="Snapchat Link"
        />
        <br />
        <br />
        <Form.Label>
          {" "}
          <img src={rumble} width={48} alt="rumble" /> <strong>RUMBLE</strong>
        </Form.Label>
        <FormControl
          name="rumble"
          defaultValue={social?.rumble}
          type="url"
          size="lg"
          placeholder="Rumble Link"
        />
        <br />
        <br />
        <Form.Label>
          <img
            src="https://img.icons8.com/fluency/48/000000/whatsapp.png"
            alt="whatsapp"
          />{" "}
          <strong>WHATSAPP</strong>
        </Form.Label>
        <FormControl
          name="whatsapp"
          defaultValue={social?.whatsapp}
          type="tel"
          size="lg"
          placeholder="Whatsapp Number"
        />
        <br />
      </Form>
    </Container>
  );
}
