
/**
 * Author: Martin Kululanga
 * Github: https://github.com/m2kdevelopments
 */

import  { useLottie } from "lottie-react";
import * as lottieLoading from '../res/lottie_loading.json';
import * as lottieNoConnection from '../res/lottie_noconnection.json';
import * as lottieUpgrade from "../res/lottie_upgrade_version.json";
import * as lottieDevelopers from "../res/lottie_boy_on_social.json";
import * as lottieCoaching from "../res/lottie_coaching.json";
import * as lottieLogin from "../res/lottie_login.json";
import * as lottieSocialSquad from "../res/lottie_social_squad.json";
import * as lottieStationery from "../res/lottie_stationery.json";
import * as lottieEmpty from "../res/lottie_empty_box.json";

const Lottie = ({lottie}) => {
  
  const options = {
    animationData: lottie.default,
    loop: true,
    autoplay: true,
  };
 
  const { View } = useLottie(options);
 
  return View;
}

 
const Animation = ({lottie, width, height, title}) => {
  return <div className="centralise" style={{width:width, height:height}}>
          <Lottie lottie={lottie} />
          <hr/>
          <h4>{title}</h4>
        </div>
}

 
export const AnimationLoading = ({width, title}) => <Animation title={title} lottie={lottieLoading} width={width} />

export const AnimationError = ({width, title}) => <Animation title={title} lottie={lottieNoConnection} width={width}  />

export const AnimationUpgrade = ({width, title}) => <Animation title={title} lottie={lottieUpgrade} width={width} />

export const AnimationDevelopers = ({width, title}) => <Animation title={title} lottie={lottieDevelopers} width={width} />

export const AnimationCoaching = ({width, title}) => <Animation title={title} lottie={lottieCoaching} width={width} />

export const AnimationLogin = ({width, title}) => <Animation title={title} lottie={lottieLogin} width={width} />

export const AnimationSocial = ({width, title}) => <Animation title={title} lottie={lottieSocialSquad} width={width} />

export const AnimationStationery = ({width, title}) => <Animation title={title} lottie={lottieStationery} width={width} />

export const AnimationEmpty  = ({width, title}) => <Animation title={title} lottie={lottieEmpty} width={width} />