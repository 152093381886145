import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import logo from "../images/logo.png";
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { ContextSetUser, ContextUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import * as API from "../utils/api";
import Cookies from 'js-cookie';


function PageLogin() {

    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const setUser = useContext(ContextSetUser);
    const user = useContext(ContextUser);

    useEffect(() => {
        if(user){
            history.push('/dashboard');
        }
    }, [user, history])

    const onFacebook = async () => {
        try {
            setLoading(true);
            const auth = getAuth();
            const provider = new FacebookAuthProvider();
            provider.setCustomParameters({ 'display': 'popup' });
            const result = await signInWithPopup(auth, provider);            
            
            if(result.user){
                setUser(result.user);
                const res = await API.PostAPI('/api/user', result.user, false);
                if(res.result){
                    Cookies.set(process.env.REACT_APP_COOKIE_KEY, res.token);
                    history.push('/links');
                    swal('Login Successful', `Welcome ${result.user.displayName}`, 'success');
                }
                
            }else{
                swal('Login Failed. Check your internet connection');
            }
            
        }catch(e){
            console.log(e.message)
            if(e.message.indexOf("auth/account-exists") !== -1){
                swal('BOS Links', `This email is logged in with another social platform`, 'info');
            }else{
                swal('BOS Links', 'Could not login with Facebook', 'info');
            }
        }finally{
            setLoading(false);
        }
    }

    const onGoogle = async () => {

        try {
            setLoading(true);
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({ 'display': 'popup' });
            const result = await signInWithPopup(auth, provider);            
            
            if(result.user){
                setUser(result.user)
                const res = await API.PostAPI('/api/user', result.user, false);
                if(res.result){
                    Cookies.set(process.env.REACT_APP_COOKIE_KEY, res.token);
                    history.push('/links');
                    swal('Login Successful', `Welcome ${result.user.displayName}`, 'success');
                }
            }else{
                swal('Login Failed. Check your internet connection');
            }
            
        }catch(e){
            console.log(e.message)
            if(e.message.indexOf("auth/account-exists") !== -1){
                swal('BOS Links', `This email is logged in with another social platform`, 'info');
            }else{
                swal('BOS Links', 'Could not login with Google', 'info');
            }
        }finally{
            setLoading(false);
        }
    }


    return (
        <Container style={{paddingTop:120, paddingBottom:40}} className="centralise">
            
                <img src={logo} alt="logo" width={300}/>
                <h1>BOS Links</h1>
                {loading ? <div className="app"><AnimationLoading width={250} title="Loading"/></div> : null}
               <br/><br/>
               <Button size="lg" disabled={loading}  onClick={onGoogle} variant="light" className="round hover dropShadow">
                    <img src="https://img.icons8.com/fluency/30/000000/google-logo.png" alt="G"/>
                    {" "}
                    <strong>Continue With Google</strong>
                </Button>             
                <br/><br/>
                <Button size="lg" disabled={loading}  onClick={onFacebook} variant="light" className="round hover dropShadow">
                    <img src="https://img.icons8.com/fluency/30/000000/facebook-new.png" alt="F"/>
                    {" "}
                    <strong>Continue With Facebook</strong>
                </Button>             
                <br/><br/>
                <Button size="lg" as="a" target="_blank" href="https://chrome.google.com/webstore/detail/bos-links/efbpcabaejajbmoambiocjihlojkcjjh" variant="light" className="round hover dropShadow">
                    <img src="https://img.icons8.com/color/30/000000/chrome.png" alt="F"/>
                    {" "}
                    <strong>Download Chrome Extension</strong>
                </Button>             
                <br/><br/>
        </Container>
    )
}

export default PageLogin
