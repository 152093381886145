
import rumble from "../../images/rumble.png";

const PlatformIcon = ({platform, size}) => {
    
    let height = size?size:80
    if(platform.match(/rumble/gmi)){
        return <img src={rumble}  style={{float:"left"}} alt="icon" height={height} />
    }else if(platform.match(/facebookMessenger/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/facebook-messenger--v2.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/website/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/domain.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/facebook/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/facebook-new.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/whatsapp/gmi)){
        return <img height={height} src="https://img.icons8.com/color/80/000000/whatsapp.png"  style={{float:"left"}} alt="icon" />
    }else if(platform.match(/youtube/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/youtube-play"  style={{float:"left"}} alt="icon" />
    }else if(platform.match(/snapchat/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/snapchat.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/instagram/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/instagram-new.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/twitter/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/twitter.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/twitch/gmi)){
        return <img height={height} src="https://img.icons8.com/external-justicon-flat-justicon/80/000000/external-twitch-social-media-justicon-flat-justicon.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/tiktok/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/tiktok.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/pinterest/gmi)){
        return <img height={height} src="https://img.icons8.com/color/80/000000/pinterest.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/reddit/gmi)){
        return <img height={height} src="https://img.icons8.com/dusk/80/000000/reddit.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/linkedin/gmi)){
        return <img height={height} src="https://img.icons8.com/color/80/000000/linkedin.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/discord/gmi)){
        return <img height={height} src="https://img.icons8.com/fluency/80/000000/discord-logo.png"  style={{float:"left"}} alt="icon"/>
    }else if(platform.match(/patreon/gmi)){
        return <img height={height} src="https://img.icons8.com/color/80/000000/patreon.png"  style={{float:"left"}} alt="icon"/>
    }else{
        return null;
    } 
}

export default PlatformIcon;