import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import phoneImage from "../images/phone.png";
import { ContextUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import ProfileDisplay from '../content/ProfileDisplay';
import * as API from "../utils/api";
import { Container } from 'react-bootstrap';

function PageMobilePreview() {

    const {pageId} = useParams();
    const [page, setPage] = useState(null);
    const [boslinks, setBoslinks] = useState([])
    const user = useContext(ContextUser);
    const [imageSize, setImageSize] = useState(100);
    const [dropShadow, setDropShadow] = useState(false);
    const [borderRadius, setBorderRadius] = useState(18);
    const [borderWidth, setBorderWidth] = useState(6);
    const [variant, setVariant] = useState("dark")
    const [opacity, setOpacity] = useState(100)
    const [buttonFontSize, setButtonFontSize] = useState(9);
    const [buttonFontColor, setButtonFontColor] = useState("#FFFFFF");
    const [borderColor, setBorderColor] = useState("#FFFFFF");
    const [h1Color, setH1Color] = useState("#FFFFFF");
    const [h2Color, setH2Color] = useState("#FFFFFF");
    const [h1, setH1] = useState(20);
    const [h2, setH2] = useState(14);
    const [color1, setBackgroundColor1] = useState("#FFFFFF");
    const [color2, setBackgroundColor2] = useState("#FFFFFF");
    const [useColor, setUseColor] = useState(false);
    const [width, setButtonWidth] = useState(200);
    const [email, setEmail] = useState(user?.email||"");
    const [bio, setBio] =  useState("")
    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [whatsapp, setWhatsapp] = useState("+265999960306")
    const [youtube, setYoutube] = useState("")
    const [image, setImage] = useState(user?.photoURL||"");
    const [name, setName] = useState(user?.displayName||"");
    const [background, setBackground] = useState("https://i.imgur.com/55hkXKa.gif");
   


    useEffect(() => {
        async function run(){
            try{
                const res = await API.GetAPI(`/api/pages/page/${pageId}`);
                const page = res.page;
                const boslinks = res.boslinks;
                setPage(page);
                setBoslinks(boslinks);
            }catch(e){
                console.log(e.message);
            }
        }

        run(); 
    }, [pageId])


    
    if(page === undefined){
        return <AnimationLoading width={400} title="Loading Page"/>
    }

    else if(page === null){
        return <AnimationLoading width={400} title="Check your internet connection"/>
    }


    const settings = {
        profile:{
            width:imageSize,
            dropShadow:true,
            image:image,
            name:name,
            email:email,
            bio:bio,
        },
        buttons:{
            variant: variant,
            theme: "purple",
            dropShadow: dropShadow,
            style:{
                width: width,
                opacity: opacity/100.0,
                borderWidth:borderWidth,
                borderColor:borderColor,
                fontColor: buttonFontColor,
                fontSize:buttonFontSize,
                borderRadius:borderRadius,
                textAlign:"left"
            }  
        },
        h1:{
            color:h1Color,
            fontSize: h1,
        },
        h2:{
            color:h2Color,
            fontSize: h2,
        },
        h3:{
            color:h2Color,
            fontSize: h2,
        },
        facebook:facebook,
        youtube:youtube,
        whatsapp:whatsapp,
        
    }



    return (
        <div>
            <Container className="centralise" style={{paddingTop:100}}>
                <div style={{width:500, height:760, background:`${useColor?`linear-gradient(${color1},${color2})`:""}`}}> 
                    {
                        useColor?null: <img src={background} style={{zIndex:0, borderRadius:26, position:"absolute", top:95, width:300, height:600}} alt="alt"/> 
                    }
                </div>
                <ProfileDisplay displaySettings={{}}  settings={settings} boslinks={boslinks} page={page}/>
                <br/>           
            </Container>
        </div>
        
    )
}   

export default PageMobilePreview
