/**
 * author: Martin Kululanga
 * github: https://github.com/m2kdevelopments
 */

 import React, {useContext, useEffect, useState} from 'react'
 import { Alert, Button, Col, Container, Form, FormControl, Row, Table } from 'react-bootstrap'
 import phoneImage from "../images/phone.png";
 import Switch from "react-switch";
 import ProfileDisplay from '../content/ProfileDisplay';
 import { ContextUser } from '../App';
 import Tab from '@mui/material/Tab';
 import Tabs from '@mui/material/Tabs';
 import TabPanel from '../components/TabPanel';
 import {Photo, Toc, PersonPin, Cable} from '@mui/icons-material';
 import * as API from "../utils/api";
 import PlatformIcon from '../components/icons/PlatformIcon';
import swal from 'sweetalert'; 
import { Link, useParams } from 'react-router-dom';
import { AnimationLoading } from '../components/Lottie';


function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const animatedImages = [
    "https://i.gifer.com/J4o.gif",
    "https://i.gifer.com/76YS.gif",
    "https://i.gifer.com/cFv.gif",
    "https://i.imgur.com/m4GZSmC.gif",
    "https://i.imgur.com/55hkXKa.gif"
]

const staticImages = [
    "https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1524169358666-79f22534bc6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80",
    "https://media.istockphoto.com/photos/sliced-figure-picture-id1300035600?k=20&m=1300035600&s=612x612&w=0&h=PzHnt-RjEZzr93a9-h4TVILWpW41j2f4CFpkcn39ShE=",
    "https://media.istockphoto.com/photos/abstract-graphic-world-map-illustration-on-blue-background-big-data-picture-id1294021851?k=20&m=1294021851&s=612x612&w=0&h=KEboppobNcVp_FTgRLcLyWym5j1KrK--U4CHGPTCVe4=",
    "https://media.istockphoto.com/photos/abstract-futuristic-with-connection-lines-on-blue-background-plexus-picture-id1285395672?k=20&m=1285395672&s=612x612&w=0&h=3vv6man6tNNmKOfGq4_UAJx0EzjI0OauJmtS9g2jjek="

]



function PageCustomize() {

    const {pageId} = useParams();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState();
    const user = useContext(ContextUser);
    const [tab, setTab] = useState(0);
    const [resources, setResources] = useState([]);
    const [imageSize, setImageSize] = useState(100);
    const [dropShadow, setDropShadow] = useState(false);
    const [borderRadius, setBorderRadius] = useState(18);
    const [borderWidth, setBorderWidth] = useState(6);
    const [variant, setVariant] = useState("dark")
    const [opacity, setOpacity] = useState(100)
    const [buttonFontSize, setButtonFontSize] = useState(9);
    const [buttonFontColor, setButtonFontColor] = useState("#FFFFFF");
    const [borderColor, setBorderColor] = useState("#FFFFFF");
    const [h1Color, setH1Color] = useState("#FFFFFF");
    const [h2Color, setH2Color] = useState("#FFFFFF");
    const [h1, setH1] = useState(20);
    const [h2, setH2] = useState(14);
    const [color1, setBackgroundColor1] = useState("#FFFFFF");
    const [color2, setBackgroundColor2] = useState("#FFFFFF");
    const [useColor, setUseColor] = useState(false);
    const [width, setButtonWidth] = useState(200);
    const [bio, setBio] =  useState("")
    const [image, setImage] = useState(user?.photoURL||"");
    const [name, setName] = useState(user?.displayName||"");
    const [background, setBackground] = useState("https://i.imgur.com/55hkXKa.gif");
    const [pageBoslinks, setPageBoslinks] =useState([]);
    const [socialLinks, setSocialLinks] = useState({});


    useEffect(() => {
        async function run(){
            try{
                const res = await API.GetAPI(`/api/pages/page/${pageId}`);
                const page = res.page;
                setPage(page);
                setName(page.name);
                setBio(page.description);
                setPageBoslinks(res.boslinks);
            }catch(e){
                console.log(e.message);
                setPage(null);
            }
        }

        async function images(){
            try{
                const staticImages = await API.GetAPI(`/api/images/static`);
                const animatedImages = await API.GetAPI(`/api/images/animated`);

                console.log(staticImages);
                console.log(animatedImages);
            }catch(e){
                console.log(e.message);
            }
        }

        run();
        images();
    }, [pageId])


    useEffect(() => {
        async function run(){
            try{
                const resources = await API.GetAPI(`/api/boslinks`);
                setResources(resources?resources:[]);
            }catch(e){
                console.log(e.message);
                setResources([]);
            }finally{
                
            }
        }
        run();
    }, []);

    const onTabChange = (event, newValue) => setTab(parseInt(newValue))

     
    const onSetLinks = async (e) => {
        e.preventDefault();
        const boslinks = [];
        resources.forEach(res=>{
            const value = e.target["boslink_"+res._id].checked;
            if(value){
                boslinks.push(res._id);
            }
        });

        const info = { ...page };
        info.settings = settings;
        info.boslinks = boslinks;
 
        try{
            setLoading(true)
            const res = await API.PatchAPI(`/api/pages/${pageId}`, info);
            if(res.result){
                const res = await API.GetAPI(`/api/pages/page/${pageId}`);
                const page = res.page;
                setPage(page);
                setName(page.name);
                setBio(page.description);
                setPageBoslinks(res.boslinks);
                swal('Content Updated', 'Content Links was update', 'success');
            }
        }catch(err){
            console.log(err.message);
        }finally{
            setLoading(false)
        }
       
        
    }

    const onUpdateContentHeader = async () => {
        try{
            setLoading(true);
            const info = {...page }
            info.name = name;
            info.description = bio;

            const res = await API.PatchAPI(`/api/pages/${pageId}`, info);
            if(res.result){
                const res = await API.GetAPI(`/api/pages/page/${pageId}`);
                const page = res.page;
                setPage(page);
                setName(page.name);
                setBio(page.description);
                setPageBoslinks(res.boslinks);
                swal('Content Updated', 'Content header content was updated', 'success');
            }
        }catch(err){
            console.log(err.message);
        }finally{
            setLoading(false)
        }
    }

    const onSocialUpdate = (e) => {
        try{
            e.preventDefault();
            const social = {
                website: e.target.website.checked,
                youtube: e.target.youtube.checked,
                facebook: e.target.facebook.checked,
                twitter: e.target.twitter.checked,
                instagram: e.target.instagram.checked,
                tiktok: e.target.tiktok.checked,
                linkedin: e.target.linkedin.checked,
                discord: e.target.discord.checked,
                twitch: e.target.twitch.checked,
                pinterest: e.target.pinterest.checked,
                snapchat: e.target.snapchat.checked,
                rumble: e.target.rumble.checked,
                whatsapp: e.target.whatsapp.checked,
            };
 
            setSocialLinks(social);
        }catch(e){
            console.log(e.message);
        }
    }

    if(page === undefined){
        return <AnimationLoading width={400} title="Loading Page Customization"/>
    }
    else if(page === null){
        return <AnimationLoading width={400} title="Check your internet connection"/>
    }


    const settings = {
        profile:{
            width:imageSize,
            dropShadow:true,
            image:image,
            name:name,
            email:"",
            bio:bio,
        },
        buttons:{
            variant: variant,
            theme: "purple",
            dropShadow: dropShadow,
            style:{
                width: width,
                opacity: opacity/100.0,
                borderWidth:borderWidth,
                borderColor:borderColor,
                fontColor: buttonFontColor,
                fontSize:buttonFontSize,
                borderRadius:borderRadius,
                textAlign:"left"
            }  
        },
        h1:{
            color:h1Color,
            fontSize: h1,
        },
        h2:{
            color:h2Color,
            fontSize: h2,
        },
        h3:{
            color:h2Color,
            fontSize: h2,
        },
        socialLinks:socialLinks,
        facebook:"",
        youtube:"",
        whatsapp:"",
        
    }

   
    return (
        <Container style={{paddingTop:80}}>
            <Row xs={1} sm={1} md={2}>
                <Col>
                    <Tabs value={tab} onChange={onTabChange} aria-label="BOS Link Customization">
                        <Tab icon={<Photo />} label="VISUALS"  {...a11yProps(0)} />
                        <Tab icon={<Toc />} label="CONTENT"  {...a11yProps(1)}/>
                        <Tab icon={<PersonPin />} label="SOCIAL"  {...a11yProps(2)}/>
                        <Tab icon={<Cable/>} label="INTEGRATIONS"  {...a11yProps(3)}/>
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Container>
                            <Alert variant="light" className="dropShadow">
                                <Alert.Heading>
                                    <h2>Header</h2>
                                    <hr/>
                                </Alert.Heading>
                            
                                <Form.Label><Form.Label><strong>Title Size ({h1}px)</strong></Form.Label></Form.Label>
                                <FormControl type="range" min={10} step={1} max={30} value={h1} onChange={(e)=>setH1(parseInt(e.target.value))} placeholder="Border Size"/>
                                <br/>
                                <Form.Label><Form.Label><strong>Title Color</strong></Form.Label></Form.Label>
                                <FormControl type="color"  placeholder="Border Color" value={h1Color} onChange={(e)=>setH1Color(e.target.value)} />
                                <br/>            
                                <Form.Label><Form.Label><strong>Sub Title ({h2}px)</strong></Form.Label></Form.Label>
                                <FormControl type="range" min={10} step={1} max={30} value={h2} onChange={(e)=>setH2(parseInt(e.target.value))} placeholder="Border Size"/>
                                <br/>  
                                <Form.Label><Form.Label><strong>Image Size ({imageSize}px)</strong></Form.Label></Form.Label>
                                <FormControl type="range" min={40} step={1} max={200} value={imageSize} onChange={(e)=>setImageSize(parseInt(e.target.value))} placeholder="Image Size"/>
                                <br/>  
                            </Alert>
                            <br/><br/>                        
                            <Alert variant="light" className="dropShadow">
                                <Alert.Heading>
                                    <h2>Buttons</h2>
                                    <hr/>
                                </Alert.Heading>
                                <Form.Label><Form.Label><strong>Border Radius ({borderRadius}px)</strong></Form.Label></Form.Label>
                                <FormControl type="range" min={0} step={1} max={30} value={borderRadius} onChange={(e)=>setBorderRadius(parseInt(e.target.value))} placeholder="Border Size"/>
                                <br/>
                                        
                                <Form.Label><strong>Border Size ({borderWidth}px)</strong></Form.Label>
                                <FormControl type="range" min={0} step={1} max={20} value={borderWidth} onChange={(e)=>setBorderWidth(parseInt(e.target.value))} placeholder="Border Size"/>
                                <br/>
                                        
                                <Form.Label><strong>Button Width ({width}px)</strong></Form.Label>
                                <FormControl type="range" min={10} step={1} max={240} value={width} onChange={(e)=>setButtonWidth(parseInt(e.target.value))} placeholder="Button Size"/>
                                <br/>
                                        
                                <Form.Label><strong>Opacity ({opacity}%)</strong></Form.Label>  
                                <FormControl type="range" min={0} step={1} max={100} value={opacity} onChange={(e)=>setOpacity(parseInt(e.target.value))} placeholder="Opacity"/>
                                <br/>
                                        
                                <Form.Label><strong>Font Size ({buttonFontSize}px)</strong></Form.Label>  
                                <FormControl type="range" min={0} step={1} max={15} value={buttonFontSize} onChange={(e)=>setButtonFontSize(parseInt(e.target.value))} placeholder="Border Size"/>
                                <br/>
                                        
                                <Form.Label><strong>Drop Shadow</strong></Form.Label>
                                
                                <Switch checked={dropShadow} onChange={setDropShadow} width={63} height={25} />
                                <br/>
                                        
                                <Form.Label><strong>Variant</strong></Form.Label>
                                <br/>
                                <select style={{padding:10, fontSize:15, borderRadius:20}} value={variant} onChange={(e)=>setVariant(e.target.value)}>
                                    <option value="dark">Dracula</option>
                                    <option value="light">Light</option>
                                    <option value="primary">Sapphire</option>
                                    <option value="info">Aqua</option>
                                    <option value="warning">Summer</option>
                                    <option value="danger">Ruby</option>
                                    <option value="success">Savanna</option>
                                </select>
                                <br/>               
                                <Form.Label><strong>Border Color</strong></Form.Label>
                                <br/>
                                <FormControl type="color"  placeholder="Border Color" value={borderColor} onChange={(e)=>setBorderColor(e.target.value)} />
                                <br/> 
                            </Alert>

                            <br/><br/>
                            <Alert variant="light" className="dropShadow">
                                <Alert.Heading>
                                    <h2>Fonts</h2>
                                    <hr/>
                                </Alert.Heading>
                                <Form.Label><strong>Font Color</strong></Form.Label>                      
                                <FormControl type="color"  placeholder="Font Color" value={buttonFontColor} onChange={(e)=>setButtonFontColor(e.target.value)} />
                                <br/>
                                <Form.Label><strong>Sub Title Color</strong></Form.Label>
                                <FormControl type="color"  placeholder="Font Color" value={h2Color} onChange={(e)=>setH2Color(e.target.value)} />            
                            </Alert>

                            <br/><br/>
                            <Alert variant="light" className="dropShadow">
                                <Alert.Heading>
                                    
                                    <hr/>
                                </Alert.Heading>
                                
                            </Alert>


                            <br/><br/>
                            <Alert variant="light" className="dropShadow">
                                <Alert.Heading>
                                    <h2>Backgrounds</h2>
                                    <hr/>
                                </Alert.Heading>
                            
                                <h4>Colors</h4>
                                <hr/>
                                <Form.Check label="Use Color" checked={useColor} onChange={(e)=>setUseColor(e.target.checked)} />
                                <br/>
                                <Form.Label><strong>Color 1</strong></Form.Label>
                                <FormControl disabled={!useColor} type="color"  placeholder="Color 1" value={color1} onChange={(e)=>setBackgroundColor1(e.target.value)} />
                                <br/>    
                                <Form.Label><strong>Color 2</strong></Form.Label>
                                <FormControl disabled={!useColor}  type="color" placeholder="Color 2" value={color2} onChange={(e)=>setBackgroundColor2(e.target.value)} />
                                <br/><br/>
                                <h4>Static</h4>
                                <hr/>
                                <Row xs={2}>
                                {
                                    staticImages.map((image, index)=> 
                                        <Col key={'static'+index}>
                                            <img className="hover" onClick={()=>setBackground(image)} src={image} width={200} height={200} alt="static"/>
                                        </Col>
                                    )
                                }
                                </Row>
                                <br/>
                                <h4>Animated</h4>
                                <hr/>
                                <Row xs={2}>
                                {
                                    animatedImages.map((image, index)=> 
                                        <Col key={'static'+index}>
                                            <img className="hover" onClick={()=>setBackground(image)} src={image} width={200} height={200}  alt="static"/>
                                        </Col>
                                    )
                                }
                                </Row>
                            </Alert>
                        </Container>
                    </TabPanel>
                
                    <TabPanel value={tab} index={1}>
                        <br/>
                        <Alert variant="light" className="dropShadow">
                            <Alert.Heading>
                                <h3>HEADER CONTENT</h3>
                            </Alert.Heading>
                            <br/>
                            <Form.Label><Form.Label><strong>Display Name</strong></Form.Label></Form.Label>
                            <FormControl type="name" value={name} onChange={(e)=>setName(e.target.value)} placeholder="Display Name"/>
                            <br/>
                            <Form.Label><Form.Label><strong>Bio ({bio.length}/100)</strong></Form.Label></Form.Label>
                            <FormControl type="bio" value={bio} onChange={(e)=>setBio(e.target.value)} maxLength={100} placeholder="Bio"/>
                            <br/>
                            <Button disabled={loading} variant="outline-primary" onClick={onUpdateContentHeader} className="round hover dropShadow">
                                <strong>UPDATE</strong>
                            </Button>
                        </Alert>

                       <Form onSubmit={onSetLinks}>

                        <Button disabled={loading} variant="outline-info" type="submit" className="round hover dropShadow">
                            <strong>USE SELECTED LINKS</strong>
                        </Button>
                        
                        <Table variant="light" className="dropShadow">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>USE</th>
                                    <th>NAME</th>
                                    <th>TYPE</th>
                                    <th>LINK</th>
                                    <th>REGISTER?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    resources.map((res, index)=>
                                        <tr>
                                            <td>{index+1}</td>
                                            <td><Form.Check defaultChecked={pageBoslinks.indexOf(res._id) !== -1}id={'boslink_'+res._id}/></td>
                                            <td>{res.name}</td>
                                            <td>{res.type}</td>
                                            <td><Button variant="light" onClick={()=>{navigator.clipboard.writeText(res.url); swal('Copied Link '+ res.name)}} className="hover dropShadow">Copy</Button></td>
                                            <td>{res.registerFirst?"YES":"NO"}<PlatformIcon size={24} platform={res.registrationPlatform} /></td>
                                        </tr>

                                    )
                                }
                            </tbody>
                        </Table>
                        </Form>
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                    <br/>
                        <Alert variant="light" className="dropShadow">
                            <Alert.Heading>
                                <h3>SOCIAL CONTENT</h3>
                                <h6><i>Edit your social links in your <Link to="/profile">Profile</Link></i></h6>
                            </Alert.Heading>
                            <hr/>

                            <Form onSubmit={onSocialUpdate}>

                                <Row xs={1} sm={2} md={3}>
                                    {["Website","Youtube","Facebook", "Twitter", "Instagram", "Tiktok", "Linkedin", "Discord", "Twitch","Pinterest", "Snapchat", "Rumble", "Whatsapp"].map(
                                        social=><Col key={social}><Form.Check name={social.toLowerCase()} label={social} /></Col>
                                    )}
                                </Row>
                                <br/><br/>
                                <Button disabled={loading} variant="outline-primary" type="submit" className="round hover dropShadow">
                                    <strong>UPDATE</strong>
                                </Button>
                            </Form>
                        </Alert>
                        
                    </TabPanel>
                </Col>
                <Col>
                        <img src={phoneImage} style={{position:"fixed", zIndex:2}} alt="phone" width={300}/>
                        <div style={{width:400, height:760, background:`${useColor?`linear-gradient(${color1},${color2})`:""}`}}> 
                        {
                            useColor?null: <img src={background} style={{zIndex:0, borderRadius:26, position:"fixed", top:95, paddingLeft:15, width:280, height:580}} alt="alt"/> 
                        }
                        </div>
                        <ProfileDisplay displaySettings={{zIndex:3, position:"fixed", top:100, width:300}} settings={settings} boslinks={pageBoslinks} page={page}/>
                        <br/>
                        <Row xs={2} style={{position:"fixed", width:600}}>
                            <Col>
                                <Button variant="light" className="dropShadow hover round">
                                    <img src="https://img.icons8.com/color/30/000000/smartphone-tablet.png" alt="mobile"/>
                                    <strong>Preview Mobile</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" className="dropShadow hover round">
                                    <img src="https://img.icons8.com/color/30/000000/laptop--v1.png" alt="laptop"/>
                                    <strong>Preview Desktop</strong>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
            </Row>
                      
        </Container>
    )
}

export default PageCustomize
