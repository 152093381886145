import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table, FormControl, Button, InputGroup, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Detail from '../components/Detail';
import { AnimationDevelopers, AnimationEmpty } from '../components/Lottie';
import * as API from "../utils/api";



function PageMyPages() {

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [code, setCode] = useState("")
    const [description, setDescription] = useState("")
    const [pages, setPages] = useState([]);
    const history = useHistory();
    const [editIndex, setEditIndex] = useState("");

    useEffect(() => {
        async function run(){
            try{
                const pages = await API.GetAPI('/api/pages');
                setPages(pages || []);
            }catch(e){
                console.log(e.message);
                setPages([]);
            }
        }

        run();
    }, [])


    const onAddPage = async (e) => {
        e.preventDefault();

        const page = {
            name:name, code:code, description:description, boslinks:[], settings:{}

        }

        try{
            setLoading(true);
            const res = await API.PostAPI('/api/pages', page);
            swal(res.result? 'Page Created' : 'Page Not Created', res.message, res.result? 'success': 'info');
            const pages = await API.GetAPI('/api/pages');
            setPages(pages);
        }catch(e){
            console.log(e.message);
            swal('Something went wrong. Check your internet.')
        }finally{
            setLoading(false);
        }
    }


    const onCustomize = (page) => {
        history.push(`/page/${page._id}`)
    }

    const onEdit = (page) => {

    }

    const onDel = async (page) => {
        const options = {
            title: "Delete Page",
            text: `Do you want to delete ${page.name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }
        const result = await swal(options);
        if(result){
            try{
                setLoading(true);
                const res = await API.DeleteAPI(`/api/pages/${page._id}`);
                swal('Page Delete', res.message, 'info');
                const pages = await API.GetAPI('/api/pages');
                setPages(pages);
            }catch(e){
                console.log(e.message);
            }finally{
                setLoading(false);
            }
        }
    }


    return (
        <Container style={{paddingTop:80}} fluid="md">

            <br/><br/>

            <Button variant="light" className="dropShadow" onClick={()=>history.goBack()}> 
                <strong>Back to Menu</strong>
            </Button>
            
            <br/><br/>
            
            <Form onSubmit={onAddPage}>             
                <Row xs={1} sm={1} md={2}>
               
                    <Col>
                        <AnimationDevelopers width={500} title="BOS Pages"/>
                    </Col>
                    <Col>
                        <Alert variant="light" className="dropShadow">
                            <Alert.Heading><h2>Create BOS Page</h2></Alert.Heading>
                            <hr/>
                            <Form.Label>Page Name ({name.length}/50)</Form.Label>
                            <FormControl placeholder="Page Name" type="text" size="lg" name="name" maxLength={50} value={name} onChange={e=>setName(e.target.value)} />
                            <br/>
                            <Form.Label>Code ({code.length}/50)</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">https://app.boslinks.com/</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl placeholder="Page Code" name="code" type="text" maxLength={50} value={code} onChange={e=>setCode(e.target.value)}/>
                            </InputGroup>
                    
                            <br/>
                            <Form.Label>Meta Description ({description.length}/200)</Form.Label>
                            <FormControl size="lg" placeholder="Meta Description" value={description} onChange={e=>setDescription(e.target.value)} type="text" as="textarea" style={{height:80}} maxLength={200} />
                            <br/><br/>
                            <Button disabled={loading} variant="info" type="submit" className="round hover dropShadow">
                                <strong>{loading?"LOADING...":"ADD PAGE"}</strong>
                            </Button>  
                        </Alert>
                    </Col>
                </Row>

                    <br/><br/>
                    <h2>PAGES</h2>
                    <Table variant="light" className="dropShadow">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>NAME</th>
                                <th>CODE</th>
                                <th>META DESCRIPTION</th>
                                <th>DESCRIPTION</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pages.map((page, index)=>
                                <tr key={page._id}>
                                    <td>{index+1}</td>
                                    <td>{page.name}</td>
                                    <td>{page.code}</td>
                                    <td><Detail max={100} text={page.meta_description} /></td>
                                    <td><Detail max={100} text={page.description} /></td>
                                    <td>
                                        <Button disabled={loading} variant="info" onClick={()=>navigator.clipboard.writeText("https://app.boslinks.com/"+page.code)} className="hover round dropShadow">
                                            <img src="https://img.icons8.com/material/24/ffffff/copy--v1.png" alt="f"/>
                                            COPY
                                        </Button>
                                        <Button disabled={loading} variant="info" onClick={()=>onCustomize(page)} className="hover round dropShadow">
                                            <img src="https://img.icons8.com/material/24/ffffff/paint--v1.png" alt="f"/>
                                            STYLE
                                        </Button>
                                        <Button disabled={loading} variant="info" onClick={()=>onEdit(page)} className="hover round dropShadow">
                                            <img src="https://img.icons8.com/ios-glyphs/30/ffffff/edit--v1.png" alt="e"/>
                                            EDIT
                                        </Button>
                                        <Button disabled={loading} variant="danger" onClick={()=>onDel(page)} className="hover round dropShadow">
                                            <img alt="v" src="https://img.icons8.com/ios-glyphs/30/ffffff/filled-trash.png"/>
                                            DEL
                                        </Button>
                                    </td>
                                </tr>
                                )
                            }
                        </tbody>
                    </Table>
        </Form>
    </Container>
    )
}

export default PageMyPages
