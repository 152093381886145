import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAk78vIi4CnHir2PK7cmYM9kI7HGiPTKVg",
  authDomain: "bos-links.firebaseapp.com",
  projectId: "bos-links",
  storageBucket: "bos-links.appspot.com",
  messagingSenderId: "811747897822",
  appId: "1:811747897822:web:ab55167f78319a2237ad4f",
  measurementId: "G-T0D5KPVJH9"
};

// Initialize Firebase
initializeApp(firebaseConfig);
 
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

