/* eslint-disable no-undef */
/**
 * Author: Martin Kululanga
 * Github: https://github.com/m2kdevelopments
*/

import axios from "axios";
import Cookies from 'js-cookie';
const instance = axios.create();
const authUrl = process.env.REACT_APP_BACKEND; //Get Urls for APIs


export const GetAPI = (route, auth=true) => Get(`${authUrl}${route}`, auth);
export const PostAPI = (route, body={}, auth=true) => Post(`${authUrl}${route}`,body, auth);
export const PatchAPI = (route, body={},auth=true) => Patch(`${authUrl}${route}`, body, auth);
export const DeleteAPI = (route, auth=true) => Delete(`${authUrl}${route}`, auth);

export function Get(url, auth=true){
    return new Promise(async resolve=>{
        
        if(auth){
            const token = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
            const headers = {'Authorization': 'Bearer ' + token};
            
            try{
                const response = await instance.get(url, {headers});
                resolve(response.data) ;
            } catch(e){
                console.log(e);
                resolve(null);
            }
            
        }else{
            try{
                const response = await instance.get(url);
                resolve(response.data) ;
            }catch(e){
                console.log({error:e});
                resolve(null);
            } 
        }
    });   
}

export function Post(url, body={}, auth=true){
    return new Promise(async (resolve) =>{
        
        if(auth){
            const token = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
            const headers = {'Authorization': 'Bearer ' + token};
            try{
                const response = await instance.post(url, body, {headers});
                return resolve(response.data) ;
            }catch(e){
                console.log(e);
                return resolve(null);
            }
        }else{
            try{
                const response = await instance.post(url, body);
                return resolve(response.data) ;
            }catch(e){
                console.log(e);
                return resolve(null);
            } 
        }
    });   
}

export function Patch(url, body={}, auth=true){
    return new Promise(async resolve=>{
        
        if(auth){
            const token = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
                const ourAppId = process.env.NEXT_PUBLIC_ID;
                
                const headers = {'Authorization': 'Bearer ' + token, 'App': ourAppId};

                
                try{
                    const response = await instance.patch(url, body, {headers});
                    resolve(response.data) ;
                }catch(e){
                    console.log(e);
                    resolve(null);
                }
                
          
        }else{
            try{
                const response = await instance.patch(url, body);
                resolve(response.data) ;
            }catch(e){
                console.log(e);
                resolve(null);
            }
      
        }
    });   
}

export function Delete(url, auth=true){
    return new Promise(async resolve=>{
        
        if(auth){
            const token = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
            //refresh user if necessary
            
            const ourAppId = process.env.NEXT_PUBLIC_ID;
            const headers = {'Authorization': 'Bearer ' + token, 'App': ourAppId};

                
            try{
                const response = await instance.delete(url, {headers});
                resolve(response.data) ;
            }catch(e){
                console.log(e);
                resolve(null);
            }
                
           
        }else{
            try{
                const response = await instance.delete(url);
                resolve(response.data) ;
            }catch(e){
                console.log(e);
                resolve(null);
            }
            
        }
    });   
}
 